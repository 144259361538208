<!--补充资料上传图片-->
<template>
  <!-- 上传附件 -->
  <div class="upload">
    <van-pull-refresh
      v-model="isLoading"
      success-text="刷新成功"
      @refresh="onRefresh"
    >
     
      <ul>
        <li v-for="(item, index) in photoList.result" :key="index">
          <p @click="toUpload(item.tit, item.type,item.dataType,item.imgData.length)" class="top">
            <template v-if="item.imgData.length > 0" >
              <!-- v-if="item.dataType=='jietu'" -->
              <img v-if="item.dataType=='jietu'" :src="item.imgData[0]" alt=""/>
               <!-- controls="controls" -->
               <template v-else>
                 <video v-if='statusType!="ios"' class='video' width="319px" height="253px" 
                  autoplay="autoplay"
                  id="myVideo" 
                  ref="video" 
                  muted="true"  
                  :src="item.imgData[0]"></video>
                  <img v-else :src="item.imgData[0]" alt=""/>
               </template>
              
            </template>
            <img v-else src="./img/addimg.png" alt=""/>
          </p>
          <p class="middle">
            <img :src="item.img" alt=""/><span
          >{{ item.tit }}
            <i v-if="item.tit != '其他'&&item.tit != '客户照片'">(必传)</i>
          </span>
          </p>
          <p class="bottom">{{ item.imgData.length }}</p>
        </li>
        <p class='footer-text'>社保(截图和录屏) 或 公积金(截图和录屏) 可选其中一组上传</p>
        <div class="submit" @click="submit">提交</div>
      </ul>
    </van-pull-refresh>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import { routerTag } from '../../utils/index'
import API from '../../server/api'
import { Toast } from 'vant'
import { Jump } from '@/utils/routh'
export default {
  setup () {
    const state = reactive({
      fileList: [],
      fileNum: 0,
      creditNo: '',
      photoList: [],
      isLoading: false,
      statusType:'',//判断机型
    })
    const toUpload = (name, type,dataType,imglength) => {
      console.log(name, type,dataType,'9765')
      // return false
       const userid=localStorage.getItem('userId')
       const creditNo = localStorage.getItem("creditNo");
      const urlInfo = `everyName=${name}&type=${type}&dataType=${dataType}&datalength=${imglength}&useIDs=${userid}`
      const urlInfos = encodeURIComponent(urlInfo)
      if(dataType=='lupin'){
        Jump(`${routerTag}screen?jsParam=${urlInfos}`)
        // console.log('外链')
        // APP.OPENWEBSITE(`${routerTag}screen?jsParam=${urlInfos}`)
      }else{
        Jump(`${routerTag}upload?jsParam=${urlInfos}`)
      }
     
    }
    // 下拉刷新
    const onRefresh = () => {
      state.isLoading = false
      getPhoto(state.creditNo)
    }
    // 获取图片信息
    const getPhoto = async (data) => {
     state.photoList = await API.getPhoto({ creditNo: data,type:'H5' });
    }
    // 点击提交
    const submit = async () => {
      let bools=true
      for (const n in state.photoList.result) {
        if(state.photoList.result[n].dataType=='jietu'){
          if(state.photoList.result[n].imgData.length==0 && state.photoList.result[n].type == 'zhima') {
            Toast.fail('请上传芝麻分截图')
            return false
          }
         
          if((state.photoList.result[n].imgData.length>0 && state.photoList.result[n].type == 'gongjijin') || (state.photoList.result[n].imgData.length>0 && state.photoList.result[n].type == 'shebao')) {
            bools=true
          }
        }else if(state.photoList.result[n].dataType=='jietu' && state.photoList.result[n].dataType=='lupin'){
          if((state.photoList.result[n].imgData.length>0 && state.photoList.result[n].type == 'gongjijin') &&(state.photoList.result[n].imgData.length>0 && state.photoList.result[n].type == 'gongjijinzhengming')){
            bools=true
          }else{
            Toast.fail('请上传社保或公积金截图或者录屏')
            return false
          }
          
        }else{
          if(state.photoList.result[n].imgData.length==0 && state.photoList.result[n].type == 'zhimatongyongfen') {
            Toast.fail('请上传芝麻分录屏')
            return false
          }
         if((state.photoList.result[n].imgData.length>0 && state.photoList.result[n].type == 'shebaozhengming')|| (state.photoList.result[n].imgData.length>0 && state.photoList.result[n].type == 'gongjijinzhengming')) {
            
             bools=true
          }
        }
        
      }
      if(bools){
        await API.submitPhoto({ creditNo: state.creditNo })
        // console.log(res,'765')
        Toast.success('提交成功')
        setTimeout(() => {
             APP.Back(1)
        }, 1000)
      }else{
        Toast.fail('请上传完整资料')
      }
      
    }
    onMounted(async () => {
      const typeBrowser=localStorage.getItem('deviceType')
      state.statusType=typeBrowser
      console.log(state.statusType,'dom1'); 
      state.creditNo = localStorage.getItem('creditNo');
      await getPhoto(state.creditNo)

    })
    return {
      ...toRefs(state),
      toUpload,
      submit,
      onRefresh
    }
  }
}
</script>

<style lang="less" src="./index.less" scoped></style>
